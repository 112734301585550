import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to register a vehicle
  REGISTER_VEHICLE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/vehicles/create-new-vehicle", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a vehicle
  EDIT_VEHICLE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/vehicles/update-vehicle/${payload.vehicleId}`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get all their vehicles
  GET_ALL_VEHICLES({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/vehicles/get-my-vehicles", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific vehicle
  GET_VEHICLE({ }, vehicleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/vehicles/get-vehicle/${vehicleId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a specific vehicle
  DELETE_VEHICLE({ }, vehicleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/vehicles/delete-vehicle/${vehicleId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to add a vehicle collaborator
  ADD_VEHICLE_COLLABORATOR({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/vehicles/add-vehicle-collaborator", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to remove a vehicle collaborator
  REMOVE_VEHICLE_COLLABORATOR({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/vehicles/remove-vehicle-collaborator", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to add a new routine check
  ADD_ROUTINE_CHECK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/vehicles/add-vehicle-routine-check-schedule", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to add a new routine check
  SCHEDULE_ALL_CHECKS({ }, vehicleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/vehicles/add-all-vehicle-routine-check-schedules/${vehicleId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to edit a routine check
  EDIT_ROUTINE_CHECK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/vehicles/edit-vehicle-routine-check-schedule", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a user to fetch a routine check
  GET_ROUTINE_CHECK({ }, checkId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/vehicles/get-vehicle-routine-check-schedule/${checkId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to delete a routine check
  DELETE_ROUTINE_CHECK({ }, checkId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/vehicles/delete-vehicle-routine-check-schedule/${checkId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to submit a completed routine check
  SUBMIT_ROUTINE_CHECK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/vehicles/submit-completed-routine-check", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to update total mileage value
  UPDATE_TOTAL_MILEAGE_VALUE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/vehicles/update-vehicle-total-mileage-value", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to set total mileage value and unit
  SET_TOTAL_MILEAGE_VALUE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/vehicles/set-vehicle-total-mileage", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
